import * as React from 'react';
import Section from "./Section";
import Content from "./Content";
import Subtitle from "./Subtitle";
import PageTitle from "./PageTitle";
import PageHeader from "./PageHeader";
import ContentColumns from "./ContentColumns";
import DoubleOffsetImage from "./DoubleOffsetImage";
import {getImage} from "../contentful/data-provider";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {renderRichText} from "gatsby-source-contentful/rich-text";

const BasicPageSection = ({basicPage, isEnvironmentContentColumns}) => {
    const renderPageTitle = () => {
        if (basicPage.subtitle) {
            return (
                <>
                    <div>{basicPage.subtitle}</div>
                    <h1>{basicPage.title}</h1>
                </>
            )
        }

        return <h1>{basicPage.title}</h1>;
    }

    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <Subtitle isBasicPageSection>
                        <h2>{children}</h2>
                    </Subtitle>
                )
            },
            [INLINES.HYPERLINK]: (node, children) => {
                console.log(node);
                return (
                    <a href={node.data.uri} target={'_blank'}>{children}</a>
                )
            },
        },
    };

    return (
        <>
            <PageHeader>
                <PageTitle>
                    {renderPageTitle()}
                </PageTitle>
            </PageHeader>
            <Section hasMaxWidth isBasicPageSection>
                <ContentColumns isFullWidth isReverseColumnOnMobile isEnvironmentContentColumns={isEnvironmentContentColumns}>
                    <div>
                        <Content>
                            <div>
                                {renderRichText(basicPage.body, options)}
                            </div>
                        </Content>
                    </div>
                    <div>
                        { basicPage.images.length >= 2 &&
                            <DoubleOffsetImage
                                firstImageSrc={getImage(basicPage.images[0]).url}
                                secondImageSrc={getImage(basicPage.images[1]).url}
                            />
                        }
                    </div>
                </ContentColumns>
            </Section>
        </>
    );
};

export default BasicPageSection;
